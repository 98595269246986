import React, { Component, useCallback } from 'react';
import db from './firebase/RealtimeDb';
import {
  auth,
  signInWithGooglePopup,
  signInWithFacebookPopup,
  signOut,
  signInAsGuest,
  createCampfireUserWithEmailAndPassword,
  sendCampfirePasswordResetLink,
  signInCampfireUserWithEmailAndPassword,
} from './firebase/FirebaseApp';
import { DidLeaveUnity } from './DidLeaveUnity'
import mixpanel from 'mixpanel-browser';
import axios from 'axios';
import ReactPixel from 'react-facebook-pixel';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import { datadogLogs } from '@datadog/browser-logs';

import './App.css';
import { errorPrefix, jsonEval } from '@firebase/util';

export default function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route path="/:id/:hangoutId" children={<UnityWrapper />} />
          <Route path="/:id" children={<UnityWrapper />} />
          <Route path="/" children={<div />} />
        </Switch>
      </Router>
    </div>
  );
}

function UnityWrapper() {
  let { id, hangoutId } = useParams();

  return (
    <div>
      <BrowserCompatibilityComponent spaceId={id} hangoutId={hangoutId} />
    </div>
  );
}

class BrowserCompatibilityComponent extends Component {

  constructor(props) {
    super(props);

    this.isMobileBrowser = this.isMobileBrowser.bind(this);
    this.isSupportedBrowser = this.isSupportedBrowser.bind(this);
    this.continueAnyway = this.continueAnyway.bind(this);

    this.state = {
      isSupportedBrowser: this.isSupportedBrowser(),
      isMobileBrowser: this.isMobileBrowser(),
      userOverride: false
    }
  }

  continueAnyway() {
    console.log(this.props.spaceId)
    this.setState({
      userOverride: true
    })
  }

  isMobileBrowser() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return true;
    }

    return false;
  }

  isSupportedBrowser() {

    var testCanvas = document.createElement('canvas');
    const gl = testCanvas.getContext('webgl2');
    if (!gl) {
      console.log("GL NOT DETECTED")
      return false;
    }

    var version = gl.getParameter(gl.SHADING_LANGUAGE_VERSION);
    console.log(version)

    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      return false
    }

    var isFirefox = /^((?!chrome|android).)*Firefox/i.test(navigator.userAgent);

    if (isFirefox) {
      return false
    }

    if (!version.includes("WebGL GLSL ES 3")) {
      return false
    }

    return true
  }


  render() {
    var content;

    if (this.state.isMobileBrowser) {
      content = (

        <div style={{ background: "black", "fontWeight": "bold", color: "white", width: "100%", height: "100%", position: "fixed", display: "inline", zIndex: 10 }}>
          <div style={{
            width: "100%", height: "100%", position: "fixed",
            top: "50%", "marginTop": "-250px", "textAlign": "center", "fontSize": "24px"
          }}>
            <div>
              <div>
                <h3 className="centerMobileOnly">Campfire is not supported on mobile. Please try this link on a PC, Mac, or Linux computer.</h3>
              </div>
            </div>
          </div>
        </div>
      )
    }
    else if (this.state.userOverride || this.state.isSupportedBrowser) {
      content = <MainComponent spaceId={this.props.spaceId} hangoutId={this.props.hangoutId} />
    } else {
      content = (

        <div style={{ background: "black", "fontWeight": "bold", color: "white", width: "100%", height: "100%", position: "fixed", display: "inline", zIndex: 10 }}>
          <div style={{
            width: "500px", height: "500px", position: "fixed",
            left: "50%", top: "50%", "marginTop": "-250px", "marginLeft": "-250px", "textAlign": "center", "fontSize": "24px"
          }}>
            <div>
              <div>
                We're sorry, it looks like your browser isn't supported. Please try the latest version of Chrome, Edge, or Brave for the best experience.
                <br></br>
                <div style={{ "marginTop": "50px", "fontSize": "40px", "letterSpacing": "10px" }}>
                  <button style={{ "width": "200px", "height": "50px" }} onClick={this.continueAnyway}>CONTINUE ANYWAY</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div>
        {content}
      </div>

    );
  }

}

class MainComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      fetchingPermissions: false,
      initCallbackData: null,
      fullyInitialized: false,
      hideLoadingExperience: false,
      didLeaveUnity: false,
      starsRating: -1,
      feedbackMessage: "",
      sessionStarted: false,
      averageFPS: -1,
      progress: 0,
      progressSimulateInterval: -1,
      errorMessage: "",
      graphicsMode: localStorage.getItem("CampfireGraphicsMode") ?? "Regular",
      restartTimestamp: "",
      roomIndex: -1,
      hangout:"",
      lobbyTime:0
    };

    this.initialize = this.initialize.bind(this);
    this.checkInitUnity = this.checkInitUnity.bind(this);
    this.onInitDataReady = this.onInitDataReady.bind(this);
    this.onLeaveUnity = this.onLeaveUnity.bind(this);
    this.rateApp = this.rateApp.bind(this);
    this.submitFeedback = this.submitFeedback.bind(this);
    this.beforeUnload = this.beforeUnload.bind(this);
    this.presenceStarted = this.presenceStarted.bind(this);
    this.presenceComplete = this.presenceComplete.bind(this);
    this.sessionStarted = this.sessionStarted.bind(this);
    this.sessionComplete = this.sessionComplete.bind(this);
    this.handleProblemWithIFrameLoad = this.handleProblemWithIFrameLoad.bind(this);
    this.loadingExperienceCompleted = this.loadingExperienceCompleted.bind(this);

    this.unityIframe = React.createRef();
    this.updateFPS = this.updateFPS.bind(this);
    this.joinRoom = this.joinRoom.bind(this);
    this.updateHangout = this.updateHangout.bind(this);
    this.updateLobbyTime = this.updateLobbyTime.bind(this);
    this.copyToClipboard = this.copyToClipboard.bind(this);
    this.onGraphicsChanged = this.onGraphicsChanged.bind(this);
    this.userAccountCreated = this.userAccountCreated.bind(this);
    this.hideLoadingExperience = this.hideLoadingExperience.bind(this);
    this.restartApp = this.restartApp.bind(this);
    this.setErrorMessage = this.setErrorMessage.bind(this);
    this.updateDownloadingProgress = this.updateDownloadingProgress.bind(this);
    this.clearProgressSimulateInterval = this.clearProgressSimulateInterval.bind(this);

    this.simulateDownloadProgress = this.simulateDownloadProgress.bind(this);
    mixpanel.init('f4b29d861b4fec75f5d7e489e215e201', { api_host: "https://proxy-mw3mwjg3ga-ue.a.run.app" });
    mixpanel.track("ReactInitializing");
  }

  simulateDownloadProgress() {
    if (this.state.progress < 90) {
      this.updateDownloadingProgress(this.state.progress + 1)
    }
  }

  componentDidMount() {
    this.initialize();

    // Hubspot chat embed
    const script = document.createElement("script");
    script.src = "//js-na1.hs-scripts.com/21396781.js";
    script.async = true;
    document.body.appendChild(script);

    ReactPixel.init('660422979193610');
  }

  checkInitUnity() {

    if (this.state.initCallbackData != null) {
      this.setState({
        fullyInitialized: true
      })
      this.unityIframe.current.sendUnityData("firebaseGetInit", JSON.stringify(this.state.initCallbackData));
    }
  }

  onInitDataReady(initCallbackData, errorMessage) {
    this.setState({
      initCallbackData: initCallbackData,
      errorMessage: errorMessage
    })
    this.checkInitUnity();
  }

  setErrorMessage(errorMessage) {
    this.setState({
      errorMessage: errorMessage
    })

    if (this.state.progressSimulateInterval != -1) {
      this.clearProgressSimulateInterval()
    }
    mixpanel.track("error_react", { "errorMessage": errorMessage })
  }

  handleProblemWithIFrameLoad(errorMessage) {
    if (!this.state.fullyInitialized) {
      this.setState({
        errorMessage: errorMessage
      })

      mixpanel.track("error_react", { "errorMessage": errorMessage })
    }

  }

  copyToClipboard(url){
    navigator.clipboard.writeText(url);
  }

  hideLoadingExperience(){
    this.setState({
      hideLoadingExperience: true
    })
  }

  userAccountCreated()
  {
    axios.get(`https://hooks.zapier.com/hooks/catch/11364489/bezayq6/?email=${encodeURIComponent(auth.currentUser.email)}&activated=true&CFURL=${encodeURIComponent(this.props.spaceId)}`)
      .then(function (response) {
        // handle success
        console.log(response);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
    const advancedMatching = { em: auth.currentUser.email };
    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    };

    ReactPixel.init('660422979193610', advancedMatching, options);
    // ReactPixel.trackSingleCustom('660422979193610', 'account_created'); // For tracking custom events.
    ReactPixel.track('CompleteRegistration');
  }

  updateLobbyTime(lobbyTime){
    this.setState({
      lobbyTime:lobbyTime
    })
  }

  updateHangout(hangoutName){
    this.setState({
      hangout:hangoutName
    })
  }

  updateFPS(averageFPS) {
    this.setState({
      averageFPS: averageFPS
    })
  }

  joinRoom(roomIndex) {
    this.setState({
      roomIndex: roomIndex
    })

    // Room has changed, no longer present in this room
    this.presenceComplete();
  }

  restartApp() {
    if (this.state.progressSimulateInterval != -1) {
      this.clearProgressSimulateInterval()
    }

    this.setState({
      restartTimestamp: Date.now(),
      errorMessage: "",
      fullyInitialized: false,
      hideLoadingExperience:false,
      progress: 0,
      averageFPS: -1,
      roomIndex: -1,
      progressSimulateInterval: -1,
      hangout: "",
      lobbyTime:0,
      loadingExperienceCompleted: false
    });

    // Refreshing
    this.presenceComplete();
    this.unityIframe.current.startIframeLoadTracker();
  }

  updateDownloadingProgress(progress) {
    if (progress > this.state.progress) {
      this.setState({
        progress: progress
      });
    }

    if (this.state.progressSimulateInterval == -1 && progress > 0) {
      this.state.progressSimulateInterval = setInterval(this.simulateDownloadProgress, 500);
    }
    if (this.state.progressSimulateInterval != -1 && progress >= 90) {
      this.clearProgressSimulateInterval()
    }
  }

  clearProgressSimulateInterval() {
    clearInterval(this.simulateDownloadProgress);
    this.state.progressSimulateInterval = -1;
  }

  onGraphicsChanged(newGraphics) {
    localStorage.setItem("CampfireGraphicsMode", newGraphics)
    mixpanel.track("GraphicsModeChanged", { "averageFPS": this.state.averageFPS, "old": this.state.graphicsMode, "new": newGraphics })

    this.setState({
      graphicsMode: newGraphics,
      averageFPS: -1,
    })

    // Refreshing 
    this.presenceComplete();
  }

  onLeaveUnity() {
    this.setState({
      didLeaveUnity: true
    });

    mixpanel.track("LeaveUnity");
    this.sessionComplete();
    this.presenceComplete();
  }

  rateApp(rating) {
    this.setState({
      starsRating: rating,
      didLeaveUnity: true
    });

    mixpanel.track("rateApp", { "rating": (rating + 1) });

    var starsTxt = "⭐️".repeat(rating + 1);
    var emptyStarsTxt = "✩".repeat(5 - (rating + 1));
    var ratingTxt = starsTxt.concat(emptyStarsTxt);

    axios.post('https://hooks.slack.com/services/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',
      {
        "text": `${ratingTxt} rating from 🔥<${this.props.spaceId}> user! Check Mixpanel to see who.`
      },
      {
        "headers": {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      })
      .then(function (response) {
        // handle success
        console.log(response);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
  }

  submitFeedback(feedbackMsg) {
    this.setState({
      feedbackMessage: feedbackMsg,
      didLeaveUnity: true
    });

    mixpanel.track("SubmitFeedback", {
      "feedbackMessage": feedbackMsg,
      "rating": (this.state.starsRating + 1)
    });

    var starsTxt = "⭐️".repeat(this.state.starsRating + 1);
    var emptyStarsTxt = "✩".repeat(5 - (this.state.starsRating + 1));
    var ratingTxt = starsTxt.concat(emptyStarsTxt);

    axios.post('https://hooks.slack.com/services/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',
      {
        "text": `${ratingTxt} feedback from 🔥<${this.props.spaceId}> user: ${feedbackMsg}`
      },
      {
        "headers": {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      })
      .then(function (response) {
        // handle success
        console.log(response);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })

  }

  initialize() {
    if (process.env.NODE_ENV !== "development") {
      console.log = () => { };
    }

    window.addEventListener('beforeunload', this.beforeUnload);
  }

  sessionStarted() {
    this.setState({
      sessionStarted: true
    })
    mixpanel.time_event("SessionComplete");
  }

  presenceStarted(name, team, room) {
    this.setState({
      presenceId: crypto.randomUUID(),
      presenceRoom: room,
      presenceTeam: team
    })

    db.PresenceStarted(this.state.presenceId, name, team, room)
  }

  presenceComplete() {
    if (this.state.presenceId) {
      db.PresenceComplete(this.state.presenceId, this.state.presenceTeam, this.state.presenceRoom)

      this.setState({
        presenceId: null,
        presenceRoom: null,
        presenceTeam: null
      })
    }
  }

  sessionComplete() {
    if (this.state.sessionStarted) {

      if(this.state.hangout !== "") {
        mixpanel.track("Hangout", {"hangoutId": this.state.hangout});
      }

      if(this.state.lobbyTime > 0){
        mixpanel.track("LobbyTime", {"Duration": this.state.lobbyTime});
      }

      this.setState({
        sessionStarted: false,
        hangout:"",
        lobbyTime:0
      })

      mixpanel.track("SessionComplete", { "averageFPS": this.state.averageFPS, "graphicsMode": this.state.graphicsMode });
    }
  }

  beforeUnload(event) {
    if (this.state.didLeaveUnity == false){
      event.preventDefault();
      event.returnValue = '';

      let self = this;
      setTimeout( function() {
        self.sessionComplete()
        self.presenceComplete()
      });
    }else{
      this.sessionComplete()
      this.presenceComplete()
    }
  }

  loadingExperienceCompleted()
  {
    console.log("transitionComplete called");

    this.setState({
      loadingExperienceCompleted: true
    });
  }

  render() {
    let loadingLayer;
    let errorLayer;
    if (this.state.errorMessage != "") {
      errorLayer = <ErrorUI
        errorMessage={this.state.errorMessage}
        restartApp={this.restartApp} />
    } else {
      errorLayer = ""
      if (!this.state.loadingExperienceCompleted) {
        loadingLayer =
          <LoadingExperienceIframe fullyInitialized={this.state.hideLoadingExperience} transitionComplete={this.loadingExperienceCompleted} />
      } else {
        loadingLayer = ""
      }
    }


    let postLeaveSurvey;
    if (this.state.didLeaveUnity) {
      // if (true) {
      postLeaveSurvey =
        <DidLeaveUnity
          starsRating={this.state.starsRating}
          rateApp={this.rateApp}
          submitFeedback={this.submitFeedback} />
    } else {
      postLeaveSurvey = ""
    }


    let unityIframe;
    if (this.state.didLeaveUnity) {
      unityIframe = ""
    } else {
      unityIframe =
        <UnityIframe
          sessionStarted={this.sessionStarted}
          presenceStarted={this.presenceStarted}
          onLeaveUnity={this.onLeaveUnity}
          updateFPS={this.updateFPS}
          joinRoom={this.joinRoom}
          updateHangout={this.updateHangout}
          updateLobbyTime={this.updateLobbyTime}
          copyToClipboard={this.copyToClipboard}
          userAccountCreated={this.userAccountCreated}
          hideLoadingExperience={this.hideLoadingExperience}
          onGraphicsChanged={this.onGraphicsChanged}
          restartApp={this.restartApp}
          updateDownloadingProgress={this.updateDownloadingProgress}
          setErrorMessage={this.setErrorMessage}
          handleProblemWithIFrameLoad={this.handleProblemWithIFrameLoad}
          graphicsMode={this.state.graphicsMode}
          restartTimestamp={this.state.restartTimestamp}
          roomIndex={this.state.roomIndex}
          spaceId={this.props.spaceId}
          hangoutId={this.props.hangoutId}
          initDataHandler={this.onInitDataReady}
          ref={this.unityIframe} />
    }

    return (
      <div>
        {unityIframe}
        {loadingLayer}
        {postLeaveSurvey}
        {errorLayer}
      </div>
    );
  }
}

class LoadingExperienceIframe extends Component {

  constructor(props) {
    super(props);
    this.onMessageReceived = this.onMessageReceived.bind(this);

    this.state = {
      isAwake:false,
      transitionInitiated:false,
      transitionComplete: false
    }

    window.addEventListener("message", this.onMessageReceived, false);
  }

  componentDidMount() {
    let loadingExperienceFailedTimeout = 7000;
    // If we don't load in 5 seconds, consider loading experience finished
    setTimeout(function() {
      if (!this.state.isAwake) {
        this.props.transitionComplete();        
      }
    }.bind(this), loadingExperienceFailedTimeout);
  }

  onMessageReceived(event) {
    try {
      var data = JSON.parse(event.data);
      if (data == null ) {
        return;
      }

      if (data.namespace === 'webgl_loader') {
        if (data.eventName === 'awake_running') {

          if (!this.state.isAwake) {
            this.setState({isAwake: true});
          }
        }

        else if (data.eventName === 'transition_out_completed') {
          this.props.transitionComplete();
          this.setState({
            transitionComplete: true,
          });
        }
      }
    } catch (e) {
      return false;
    }
  }

  componentDidUpdate()
  {
    if (this.props.fullyInitialized && !this.state.transitionInitiated && this.framewindow) {
      setTimeout(function() {
        this.framewindow.contentWindow.postMessage(JSON.stringify({ namespace: 'webgl_loader', eventName: 'unity_loaded', arg: [0] }), '*');
      }.bind(this), 2400);

      setTimeout(function() {
        if (!this.state.transitionComplete) {
          this.props.transitionComplete();
          this.setState({
            transitionComplete: true, 
          });
        }
      }.bind(this), 7200);

      this.setState({transitionInitiated: true});
    }
  }

  render() {
    let iframeUrl = "https://loading.campfire.to"

    return (
      <div style={{ height: "100%", width: "100%", position: "absolute" }}>
        <iframe
          ref={e => {
            this.framewindow = e;
          }}
          title="unity"
          src={iframeUrl}
          position="absolute"
          width="100%"
          height="100%"
          frameBorder="0"
          style={{ height: "100%", width: "100%" }} />
      </div>
    );
  }
}


class LoadingUI extends Component {

  render() {
    let downloadingText = this.props.progress < 100 ? " Loading Campfire " + this.props.progress + "%" : "Preparing space...";

    let content =
      <div style={{ width: "300px", "marginLeft": "100px", "textAlign": "center" }}>
        <div style={{ display: "inline-block" }}>
          <img alt="" src="fire.gif" width="250px" />
        </div>
        <div style={{ "marginTop": "10%" }}>
          {downloadingText}
        </div>
        <progress id="progressBar" value={this.props.progress} max="100"> {this.props.progress}% </progress>
      </div>


    return (
      <div style={{ background: "black", "fontWeight": "bold", color: "white", width: "100%", height: "100%", position: "fixed", display: "inline", zIndex: 10 }}>
        <div style={{
          width: "500px", height: "500px", position: "fixed",
          left: "50%", top: "50%", "marginTop": "-250px", "marginLeft": "-250px"
        }}>
          {content}
        </div>
      </div>
    );
  }
}

class ErrorUI extends Component {

  render() {

    let content =
      <div style={{ width: "300px", "marginLeft": "100px", "textAlign": "center" }}>
        <div unselectable="on" style={{ "marginTop": "10%" }}>
          {this.props.errorMessage}
        </div>

        <div style={{ "marginTop": "50px", "fontSize": "40px", "letterSpacing": "10px" }}>
          <button style={{ "width": "200px", "height": "50px" }} onClick={this.props.restartApp}>REFRESH</button>
        </div>
      </div>

    return (
      <div style={{ background: "black", "fontWeight": "bold", color: "white", width: "100%", height: "100%", position: "fixed", display: "inline", zIndex: 10 }}>
        <div style={{
          width: "500px", height: "500px", position: "fixed",
          left: "50%", top: "50%", "marginTop": "-250px", "marginLeft": "-250px"
        }}>
          {content}
        </div>
      </div>
    );
  }
}


class UnityIframe extends Component {

  iframeLoadStarted = false;

  constructor(props) {
    super(props);
    this.onMessageReceived = this.onMessageReceived.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.sendUnityData = this.sendUnityData.bind(this);
    this.onVisibilityChange = this.onVisibilityChange.bind(this);
    this.onMicrophonePermissionStatusChange = this.onMicrophonePermissionStatusChange.bind(this);
    this.onCameraPermissionStatusChange = this.onCameraPermissionStatusChange.bind(this);
    this.flushNetworking = this.flushNetworking.bind(this);
    this.handleFirebaseGet = this.handleFirebaseGet.bind(this);
    this.handleFirebaseSet = this.handleFirebaseSet.bind(this);
    this.handleFirebaseUpdate = this.handleFirebaseUpdate.bind(this);
    this.handleFirebaseOnValue = this.handleFirebaseOnValue.bind(this);
    this.handleFirebaseRemoveOnValue = this.handleFirebaseRemoveOnValue.bind(this);
    this.handleFirebaseGetInit = this.handleFirebaseGetInit.bind(this);
    this.handleFirebaseGetUser = this.handleFirebaseGetUser.bind(this);
    this.handleFirebaseResetPassword = this.handleFirebaseResetPassword.bind(this);
    this.handleFirebaseSignout = this.handleFirebaseSignout.bind(this);
    this.handleFirebaseCreateUser = this.handleFirebaseCreateUser.bind(this);
    this.handleSimpleFirebaseCommand = this.handleSimpleFirebaseCommand.bind(this);
    this.onGetUserReply = this.onGetUserReply.bind(this);
    this.firebaseListener = null;
    this.startIframeLoadTracker = this.startIframeLoadTracker.bind(this);
    this.clearIFrameLoadTracker = this.clearIFrameLoadTracker.bind(this);
    this.broadcastInitializeLoadingTransition = this.broadcastInitializeLoadingTransition.bind(this);

    this.startIframeLoadTracker();
  }

  startIframeLoadTracker() {
    if (!this.activeIFrameTimeout) {
      this.activeIFrameTimeout = true;
      this.loadingStartTime = new Date();
      this.loadIframeTimeOut = setInterval(this.handleIframeLoadIssue.bind(this), 300000);
    }
  }

  handleIframeLoadIssue() {
    this.props.handleProblemWithIFrameLoad("There's a problem with loading Campfire :( Please try refreshing your browser or using incognito mode [1]");

 //   mixpanel.track("AppBootUpFailed");
    this.clearIFrameLoadTracker();
  }

  clearIFrameLoadTracker() {
    if(this.activeIFrameTimeout === true){
      this.AppBootUpTime = (new Date().getTime() - this.loadingStartTime.getTime()) / 1000;
    }
    this.activeIFrameTimeout = false;
    clearInterval(this.loadIframeTimeOut);
  }

  componentDidMount() {
    window.addEventListener("message", this.onMessageReceived, false);

    window.addEventListener('visibilitychange', this.onVisibilityChange);

    navigator.permissions.query(
      { name: 'microphone' }
    ).then(permissionStatus => {
      permissionStatus.onchange = () => this.onMicrophonePermissionStatusChange(permissionStatus);
    });

    navigator.permissions.query(
      { name: 'camera' }
    ).then(permissionStatus => {
      permissionStatus.onchange = () => this.onCameraPermissionStatusChange(permissionStatus);
    });

    this.framewindow.focus();
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.onMessageReceived);

    window.removeEventListener('visibilitychange', this.onVisibilityChange);

    this.firebaseListener && this.firebaseListener();
  }

  sendUnityData(dataKey, dataVal) {
    let msg = JSON.stringify({
      "campfire": {
        "key": dataKey,
        "val": dataVal
      }
    });

    console.log("Sending to iframe")
    console.log(msg)
    this.framewindow.contentWindow.postMessage(msg, '*');
  }

  onMicrophonePermissionStatusChange(permissionStatus) {
    this.framewindow.contentWindow.postMessage("cacheDevices", '*');
    this.sendUnityData("audioPermissionChanged", JSON.stringify({
      "deviceType": "audio",
      "status": permissionStatus.state
    }));
  }

  onCameraPermissionStatusChange(permissionStatus) {
    this.framewindow.contentWindow.postMessage("cacheDevices", '*');
    this.sendUnityData("videoPermissionChanged", JSON.stringify({
      "deviceType": "video",
      "status": permissionStatus.state
    }));
  }

  onVisibilityChange(event) {
    if (document.visibilityState === 'visible') {
      this.sendUnityData("focusEvent", JSON.stringify({ "inFocus": true }));

      if (this.state.networkFlushIntervalId) {
        clearInterval(this.state.networkFlushIntervalId);
      }
    } else {
      this.sendUnityData("focusEvent", JSON.stringify({ "inFocus": false }));

      var intervalId = setInterval(this.flushNetworking, 250);
      this.setState({ networkFlushIntervalId: intervalId });
    }
  }

  flushNetworking() {
    this.sendUnityData("flushNetworking", "");
  }

  broadcastInitializeLoadingTransition()
  {
  }

  onMessageReceived(event) {
    try {

      if (event.data.namespace === 'webgl_loader')
      {
        console.log("WE HAVE EVENT");
        if (event.data.eventName === 'transition_out_completed')
        {

        }
      }

        if (event.data.hasOwnProperty('internalData')) {
          var unityData = event.data.internalData
          if (unityData.type == "uploadFileToS3") {
            fetch(unityData.url,{
              method:'PUT',
              body:new Blob([unityData.data], {type: 'image/jpeg'})
            }).then(response=>{
              if (!response.ok) {
                throw new Error("HTTP error " + response.status);
              } else {
                console.log("Upload complete")
              }
            });
          }
        }

        var msg = JSON.parse(event.data);
        if (msg == null ) {
          return;
        }

      if (('unityLoadingDataProgress') in msg) {
        const flooredProgress = Math.floor((100 * msg.unityLoadingDataProgress.progress));

        if (!this.iframeLoadStarted && flooredProgress >= 89) {
          mixpanel.track("UnityDownloadingDataFinished");
          mixpanel.track("UnityLoadingSceneStarted");
          this.iframeLoadStarted = true;
        }

        this.props.updateDownloadingProgress(flooredProgress);
        return;
      }

      if (('unityDownloadingDataStarted') in msg) {
        console.log("Unity Downloading Data started");
        mixpanel.track("UnityDownloadingDataStarted");
        return;
      }

      if (('unityLoadSceneFinished' in msg)) {
        console.log("Unity Load Scene finished");
        mixpanel.track("UnityLoadSceneFinished");
        return;
      }

      if (!('unityData' in msg)) {
        return;
      }

      if (this.activeIFrameTimeout) {
        this.clearIFrameLoadTracker();
      }
      var unityData = msg.unityData

      switch (unityData.type) {
        case "firebaseGetInit":
          this.handleFirebaseGetInit(unityData);
          break;
        case "firebaseCreateUser":
          this.handleFirebaseCreateUser(unityData);
          break;
        case "firebaseGetUser":
          this.handleFirebaseGetUser(unityData);
          break;
        case "firebaseResetPassword":
          this.handleFirebaseResetPassword(unityData);
          break;
        case "firebaseSignout":
          this.handleFirebaseSignout(unityData);
          break;
        case "firebaseGet":
          this.handleFirebaseGet(unityData);
          break;
        case "firebaseOnValue":
          this.handleFirebaseOnValue(unityData);
          break;
        case "firebaseRemoveOnValue":
          this.handleFirebaseRemoveOnValue(unityData);
          break;
        case "firebaseSet":
          this.handleFirebaseSet(unityData);
          break;
        case "firebaseUpdate":
          this.handleFirebaseUpdate(unityData);
          break;
        case "setUserDisplayName":
          this.setUserDisplayName(unityData, unityData.newUserDisplayName);
          break;
        case "simpleCampfireCommand":
          this.handleSimpleFirebaseCommand(unityData);
          break;
      }
    } catch (e) {
      return false;
    }
  }

  handleFirebaseGetUser(unityData) {
    if (unityData.signinType === "googleSignin") {
      signInWithGooglePopup((res, error) => {
        this.onGetUserReply(res, error, unityData);
      });
    }
    else if (unityData.signinType === "facebookSignin") {
      signInWithFacebookPopup((res, error) => {
        this.onGetUserReply(res, error, unityData);
      });
    }
    else if (unityData.signinType === "anonymous") {
      signInAsGuest((res, error) => {
        this.onGetUserReply(res, error, unityData);
      });
    }
    else if (unityData.signinType === "emailSignin") {
      var Data = JSON.parse(unityData.signinData);
      signInCampfireUserWithEmailAndPassword(Data["Email"], Data["Password"], (res, error) => {
        this.onGetUserReply(res, error, unityData);
      });
    }
  }

  async onGetUserReply(res, error, unityData) {
    var userId = res?.user.uid;
    var [userGlobalDataSuccess, userGlobalData] = await db.GetAsync(`authenticatedUsersData/${userId}/userGlobalData`);

    const userData = {
      'userId': res?.user.uid,
      'email': res?.user.email,
      'userGlobalData': userGlobalDataSuccess === true ? userGlobalData : null
    }

    const data = {
      "callType": unityData.type,
      "callId": unityData.callId,
      "result": res != null ? JSON.stringify(userData) : null,
      "error": error
    };
    this.sendUnityData("firebaseGetUser", JSON.stringify(data));
  }

  handleFirebaseCreateUser(unityData) {
    createCampfireUserWithEmailAndPassword(unityData.email, unityData.password, (result, error) => {
      this.sendUnityData("firebaseCreateUser", JSON.stringify({
        "callType": unityData.type,
        "callId": unityData.callId,
        "result": result === null ? null : JSON.stringify(result),
        "error": error
      }));
    });
  }

  handleFirebaseResetPassword(unityData) {
    sendCampfirePasswordResetLink(unityData.email, (result, error) => {
      this.sendUnityData("firebaseResetPassword", JSON.stringify({
        "callType": unityData.type,
        "callId": unityData.callId,
        "result": result === null ? null : JSON.stringify(result),
        "error": error
      }));
    });
  }

  handleFirebaseSignout(unityData) {
    signOut((result, error) => {});
  }

  handleFirebaseGet(unityData) {
    db.Get(unityData.refPath, (result, error) => {
      this.sendUnityData("firebaseGet", JSON.stringify({
        "callType": unityData.type,
        "callId": unityData.callId,
        "result": result === null ? null : JSON.stringify(result),
        "error": error
      }));
    })
  }

  handleFirebaseOnValue(unityData) {
    db.OnValue(unityData.refPath, unityData.callId, (result, error) => {
      console.log(`Sending down result ${JSON.stringify(result)}`)

      this.sendUnityData("firebaseOnValue", JSON.stringify({
        "callType": unityData.type,
        "callId": unityData.callId,
        "result": result === null ? null : JSON.stringify(result),
        "error": error
      }));
    })
  }

  handleFirebaseRemoveOnValue(unityData) {
    var success = false;
    if (db.RemoveOnValue(unityData.removeCallId)) {
      success = true;
    }

    this.sendUnityData("firebaseRemoveOnValue", JSON.stringify({
      "callType": unityData.type,
      "callId": unityData.callId,
      "result": success,
      "error": success ? null : "Could not remove onValue with callId " + unityData.removeCallId
    }))
  }

  handleFirebaseSet(unityData) {
    db.Set(unityData.refPath, JSON.parse(unityData.data), (result, error) => {
      console.log(`Sending down ${JSON.stringify(result)}`)

      this.sendUnityData("firebaseSet", JSON.stringify({
        "callType": unityData.type,
        "callId": unityData.callId,
        "result": result === null ? null : JSON.stringify(result),
        "error": error
      }));
    })
  }

  handleFirebaseUpdate(unityData) {
    db.Update(unityData.refPath, JSON.parse(unityData.data), (result, error) => {
      this.sendUnityData("firebaseUpdate", JSON.stringify({
        "callType": unityData.type,
        "callId": unityData.callId,
        "result": result === null ? null : JSON.stringify(result),
        "error": error
      }));
    })
  }

  async handleFirebaseGetInit(unityData) {
    const spaceId = this.props.spaceId;
    const userId = auth?.currentUser?.uid;

    var [userSpaceConfigSuccess, userSpaceConfig] = await db.GetAsync(`authenticatedUsersData/${userId}/spacesData/${spaceId}`);
    var [spaceConfigSuccess, spaceConfig] = await db.GetAsync(`spaces/${spaceId}`);
    var [privateInfoSuccess, privateInfo] = await db.GetAsync(`authenticatedUsersData/${userId}/privateInfo`);
    var [userGlobalDataSuccess, userGlobalData] = await db.GetAsync(`authenticatedUsersData/${userId}/userGlobalData`);
  
    if (spaceConfigSuccess === true && spaceConfig !== null) {
      spaceConfig[`spaceIdentifier`] = spaceId;
      spaceConfig[`networkRoomPrefix`] = spaceConfig.spaceId;
    }

    if (!spaceConfig) {
      this.props.setErrorMessage("Problem with load space config, please contact with support [-100]")
      return;
    }

    document.title = `Campfire - ${spaceConfig.name} World`;

    var user = null;

    if (auth?.currentUser != null) {
      let emailVerified = auth.currentUser.emailVerified;
      if(auth.currentUser.isAnonymous){
        emailVerified = true;
      }
      
      if(!emailVerified){
        // workaround for facebook login the emailVerified is false for Facebook provider
        if(auth.currentUser.providerData.length > 0){
          if(auth.currentUser.providerData[0].providerId === 'facebook.com'){
            emailVerified = true;
          }else if(auth.currentUser.providerData[0].providerId === 'google.com') {
            emailVerified = true;
          }
        }
      }

      if(emailVerified){
        user = {
          email: auth.currentUser.email,
          userId: userId,
          userGlobalData: userGlobalDataSuccess === true ? userGlobalData : null
        };
      }

    }

    await navigator.permissions.query(
      { name: 'microphone' }
    ).then(permissionStatus => {
      this.onMicrophonePermissionStatusChange(permissionStatus);
    });

    await navigator.permissions.query(
      { name: 'camera' }
    ).then(permissionStatus => {
      this.onCameraPermissionStatusChange(permissionStatus);
    });

    let initData = {
      'forceRoom': this.props.roomIndex,
      'spaceConfig': spaceConfig,
      'user': user,
      'graphicsMode': this.props.graphicsMode,
      'privateInfo': privateInfoSuccess === true ? privateInfo : null,
      'joiningHangoutId' : this.props.hangoutId
    };

    if (auth?.currentUser != null && !auth.currentUser.isAnonymous && auth.currentUser.emailVerified && userSpaceConfig != null) {
      initData[`userSpaceConfig`] = userSpaceConfigSuccess === true ? userSpaceConfig : null;
      console.log(`User space data: ${JSON.stringify(userSpaceConfig)}`);
    } else {
      console.log(`No user space data!`)
    }

    const initCallbackData = {
      "callType": unityData.type,
      "callId": unityData.callId,
      "result": initData === null ? null : JSON.stringify(initData),
      "error": spaceConfig === null ? "No Space Found" : null
    };

    let errorMessage = spaceConfig === null ? "Problem with load space data, please restart the app. [0]" : "";
    this.props.initDataHandler(initCallbackData, errorMessage);
  }

  setUserDisplayName(unityData, newUserName) {
    const spaceId = this.props.spaceId;
    const userId = auth.currentUser.uid;
    const userNameMaxLength = 32;
    if (newUserName.length > userNameMaxLength) {
      newUserName = newUserName.substring(0, userNameMaxLength);
    }

    const updates = {};
    updates[`authenticatedUsersData/${userId}/spacesData/${spaceId}/displayName`] = newUserName;

    db.Update(updates, (result, error) => {
      this.sendUnityData("firebaseSet", JSON.stringify({
        "callType": unityData.type,
        "callId": unityData.callId,
        "result": result === null ? null : newUserName,
        "error": error
      }));
    });
  }

  handleSimpleFirebaseCommand(unityData) {
    var data = null
    if (unityData.value) {
      try {
        data = JSON.parse(unityData.value)
      } catch {
        // Not json data
      }
    }

    if (unityData.key === "LeaveUnity") {
      this.props.onLeaveUnity();
    }
    else if (unityData.key === "PresenceStarted") {
      this.props.presenceStarted(data.name, data.spaceId, data.room)
    }
    else if(unityData.key === "MixpanelIdentifyAge"){
      mixpanel.identify(auth.currentUser.uid);
      mixpanel.people.set({
        "age": data.age
      });
    }
    else if(unityData.key === "MixpanelIdentifyCreatedAccount"){
      mixpanel.identify(auth.currentUser.uid);
      mixpanel.people.set({
        "created_at": data.created
      });
    }
    else if (unityData.key === "MixpanelIdentify") {
      mixpanel.identify(auth.currentUser.uid);

      mixpanel.people.set({
        "$name": data.name,
        "$email": data.email,
        "$userId": data.email,
        "firebaseId": auth.currentUser.uid,
        "Username": data.name,
        "Team ID": data.teamId,
        "Graphics Device Name": data.graphicsDeviceName
      });
      mixpanel.track("AppBootUp", {"totalTime": this.AppBootUpTime});

      axios.post('https://hooks.slack.com/services/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',
        {
          "text": `🔥<${this.props.spaceId}> user 👱‍♂️<<${data.email}>> is live!`
        },
        {
          "headers": {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        })
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        })

      // Once they have identified themselves, start timing their session
      this.props.sessionStarted()
    } else if (unityData.key === "MixpanelTrack") {
      if (data.properties === null) {
        mixpanel.track(data.name);
      } else {
        mixpanel.track(data.name, data.properties);
      }
    } else if (unityData.key === "MixpanelTimeEvent") {
      mixpanel.time_event(data.name);
    } else if (unityData.key === "SetGraphicsMode") {
      this.sendUnityData("MinimizeFullscreen");
      var newGraphics = unityData.value
      // Give unity time to stop
      setTimeout(() => {
        this.props.onGraphicsChanged(newGraphics)
      }, 1000);
    } else if (unityData.key == "UpdateFPS") {
      this.props.updateFPS(parseInt(unityData.value));
    } else if (unityData.key == "JoinRoom") {
      this.props.joinRoom(parseInt(unityData.value));
    } else if (unityData.key == "UpdateHangout"){
      this.props.updateHangout(unityData.value);
    } else if (unityData.key == "LobbyTime"){
      this.props.updateLobbyTime(unityData.value);
    } else if (unityData.key == "CopyToClipboard"){
      this.props.copyToClipboard(unityData.value);
    } else if (unityData.key == "UserAccountCreated"){
      this.props.userAccountCreated();
    }else if (unityData.key == "HideLoadingExperience"){
      this.props.hideLoadingExperience();
    }

  }

  handleClick() { }
  render() {
    let iframeUrl = "https://party1.campfire.to/release/Sylwester-05-16-2023_18:47:35-67b8/index.html"
    iframeUrl += "?quality=" + this.props.graphicsMode;
    if (this.props.graphicsMode === "Performance") {
      iframeUrl += "&res=720"
    } else {
      // Other modes we don't restrict resolution
    }

    iframeUrl += `&roomIndex=${this.props.roomIndex}`
    if (this.props.restartTimestamp > 0) {
      iframeUrl += "?" + this.props.restartTimestamp
    }

    return (
      <div style={{ height: "100%", width: "100%", position: "absolute" }}>
        <iframe
          ref={e => {
            this.framewindow = e;
          }}
          title="unity"
          src={iframeUrl}
          position="absolute"
          width="100%"
          height="100%"
          allow="microphone *; camera *; fullscreen *; display-capture *;"
          frameBorder="0"
          style={{ height: "100%", width: "100%" }} />
      </div>
    );
  }
}

